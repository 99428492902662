<template>
  <div class='campaign-card rounded-7px overflow-hidden'>
    <template v-if="campaign">
      <div class="campaign-image relative">
        <a :href="campaign.link" class="block w-full">
          <div class="campaign-image-wrapper relative">
            <div class="aspect-container">
              <div class="h-full w-full bg-grey-300 absolute inset-0"></div>
              <img v-lazy='campaign.banner_xl.x3.url'
                  :alt="campaign.title" 
                  class="image absolute inset-0 w-full h-full object-cover"
                  @load="imageLoaded = true"
                  :class="{ 'opacity-0': !imageLoaded }" />
            </div>
          </div>
        </a>
       
        <div class="campaign-data-container w-full flex items-center absolute bottom-0 left-0 mb-2 ml-2 bg-filter">
          <div class="post-view-count flex items-center pr-2">
            <div class="icon mr-1 w-4 h-4"></div>
              {{ formatViewsCount(campaign.views_count) }}
          </div>
          <div class="post-likes-count flex items-center pr-2">
            <div class="icon mr-1 w-4 h-4"></div>
              {{ formatViewsCount(campaign.likes_count) }}
          </div>
          <div  class="post-downloads-count flex items-center pr-2">
            <div class="icon mr-1 w-4 h-4"></div>
              {{ formatViewsCount(campaign.downloads_count) }}
          </div>
        </div>
      </div>

      <div class='px-4 py-4 bg-grey-4'> 
        <div class="flex justify-between mb-4">
          <div class='w-4/5 post-title truncate'>
            <a :href="campaign.link"  class="hover:underline">
              {{ campaign.title }}
            </a>
          </div>

        </div>
        <div v-if="campaign.campaign_tag_list?.length > 1" 
            class="overflow-hidden flex flex-wrap gap-2 gap-y-4 text-14px h-[42px]">
          <span v-for='tag in campaign.campaign_tag_list' 
                class="inline-flex px-4 py-1 bg-[#4B4B4B] rounded-[24px] shrink-0 max-h-[32px] items-center capitalize">
            {{ tag }} 
          </span>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- Skeleton screen -->
      <div class="skeleton-card">
        <div class="skeleton-image h-40 bg-grey-300"></div>
        <div class="skeleton-data-container p-4">
          <div class="skeleton-title h-6 bg-grey-300 mb-2"></div>
          <div class="skeleton-metrics flex justify-between">
            <div class="skeleton-metric h-4 bg-grey-300 w-1/3"></div>
            <div class="skeleton-metric h-4 bg-grey-300 w-1/3"></div>
            <div class="skeleton-metric h-4 bg-grey-300 w-1/3"></div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      imageLoaded: false
    }
  },
  methods: {
    formatViewsCount(count) {
      return new Intl.NumberFormat().format(count);
    }
  }
});
</script>
