import { createApp, reactive } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';

import PostIndexCollection from '../pages/PostIndexCollection.vue';

import PostItem from '../components/PostItem.vue';
import AlertModal from '../components/AlertModal.vue';
import RequestModal from '../components/RequestModal.vue';

class PostIndexComponent {
  constructor({ container }) {
    this.app = null;
    this.container = container;
  }

  init() {
    this.app = createApp(PostIndexCollection);
    this.app.use(I18NextVue, { i18next });
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });

    this.app.component('post-item', PostItem);
    this.app.component('alert-modal', AlertModal);
    this.app.component('request-modal', RequestModal);

    // Mount the Vue app to the specified container
    this.app.mount(this.container);
  }
}

export default PostIndexComponent;