<template>
  <div class="flex items-center my-4">
    <a :href="collectionsPath" class="text-12px">{{ $t('post_index_collection.collections') }}</a>
      <img src="../../../../images/breadcrumb_arrow.svg" class="inline-block mx-2 w-4 h-4" alt="Breadcrumb Arrow" />
      <span class="text-12px text-grey-5">{{ $t('post_index_collection.edit_collection') }}</span>
  </div>
  <base-collection-form 
    mode="edit"
    :initial-data="{
      id: collection.id,
      title: collection.title,
      is_public: collection.is_public,
      selectedItems: collection.post_ids
    }"
  />
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EditCollection',
  props: {
    collection: {
      type: Object,
      required: true
    }
  },
  computed: {
    collectionsPath() {
      const currentPath = window.location.pathname;
      return currentPath.split('/').slice(0, -2).join('/');
    }
  }
});
</script>