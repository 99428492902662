import { createApp } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';

import PostRelatedModel from '../pages/PostRelatedModel.vue';

import PostItem from '../components/PostItem.vue';

class PostRelatedPostsComponent {
  constructor({ container }) {
    this.app = null;
    this.container = container;
  }

  // Initialize the component
  init() {
    this.app = createApp(PostRelatedModel);
    this.app.use(I18NextVue, { i18next });
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });

    this.app.component('post-item', PostItem);
    // Mount the Vue app to the specified container
    this.app.mount(this.container);
  }
}

export default PostRelatedPostsComponent;
