<template>
  <div>
    <div class="grid grid-cols-[33fr_47fr_75fr_36fr_16fr] rounded-8px" :class="post.unread ? 'bg-unread' : 'bg-grey-2'">
      <div class='p-2'>
        <div class="rounded-8px overflow-hidden relative">
          <div v-if="post.phrozen_verified" class="w-5 h-5 flex items-center justify-center absolute top-0 bg-red rounded-br-md text-14px bg-[#E60044]">
            <img src="../../images/home/phrozen_verified.svg" class="w-4 h-4 brightness-[4]" />
          </div>
          <img :src="post.cover_image_src" :alt="post.title" />
        </div>
      </div>
      <div class='py-2'>
        <div class="flex flex-col justify-between h-full pr-3 break-word">
          <div class="post_title text-14px font-medium hover:underline">
            <a :href="post.visible ? `/posts/${post.id}` : `/posts/${post.id}/preview`" class="">
              {{ post.title }}
            </a>
          </div>
          <div class="timestamp text-12px text-grey-5">
            <div class="created">
              Create: {{ formatDate(post.created_at) }}
            </div>
            <div class="updated">
              Update: {{ formatDate(post.updated_at) }}
            </div>
          </div>
        </div>
      </div>
      <div class='py-2'>
        <div class="variant max-h-[116px] h-full overflow-auto scrollbar">
          <div class="grid grid-custom grid-cols-[35fr_18fr_18fr] gap-x-2 gap-y-1 text-14px">
            <template v-for="variant in post.variants" :key="variant.id">
              <div class="name truncate h-5" :title="variant.name">
                {{ variant.name }}
              </div>
              <div class="price h-5">
                {{ formatCurrency(variant.price) }}
              </div>
              <div class="original_price h-5">
                {{ formatCurrency(variant.original_price) }}
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class='py-2'>
        <div class="flex flex-col justify-center items-center h-full">
            <div class="w-4 h-4 rounded-full my-1" :style="{ background: `var(--color-post-state-${post.state.replace(/_/g, '-')})` }"></div>
          <div class="state text-14px font-medium text-center">
            {{ $t(`post.state.${post.state}`) }}
          </div>
          <a v-if="post.state === 'draft'" 
             href="#" 
             class="min-w-14 btn btn-primary mt-2 py-1 px-2 text-12px"
             @click.prevent="submitAction"
             v-bind="isSubmitting ? { disabled: true } : {}">
             {{ isSubmitting ? $t('post.state.pending') : $t('models.index.submit') }}
          </a>
          <a v-else-if="post.state === 'submit_rejection'" 
             href="#" 
             class="btn btn-primary mt-2 py-1 px-2 text-12px" 
             disabled>
             {{ $t('models.index.submit') }}
          </a>
          <div v-if="post.submit_errors" class="mt-2">
            <nav-panel :submitErrors="post.submit_errors"
                      :panelClass="'w-[224px]'">
              <template #icon>
                <img src="../../images/circle_warning.svg" class="w-6 h-6" alt="Warning" />
              </template>
            </nav-panel>
          </div>
        </div>
      </div>
      <div class='py-2 pr-2'>
        <div class="flex flex-col justify-center items-center h-full">
          <a :href="`/profile/models/${post.id}/edit`" class="edit-btn flex justify-center items-center w-8 h-8 rounded-full m-1.5 btn-primary">
            <img src="../../images/edit.svg" class="w-8 h-8 object-contain" />
          </a>
          <a href="#" class="delete-btn flex justify-center items-center w-8 h-8 rounded-full m-1.5 btn-secondary"
             @click.prevent="confirmDelete">
             <img src="../../images/trash.svg" class="w-8 h-8 object-contain" />
          </a>
        </div>
      </div>
    </div>
    <hr v-if="!isLast" class='border-grey-3 my-2'>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';

export default defineComponent({
  props: {
    post: {
      type: Object,
      required: true
    },
    isLast: {
      type: Boolean,
      default: false
    },
    isSubmitting: {
      type: Boolean,
      default: false
    }
  },
  emits: ['post-submit', 'post-deleted'],
  setup(props, { emit }) {
    const mayAskForSubmit = computed(() => {
      return props.post.state === 'draft' || props.post.state === 'submit_rejection'
    })

    const formatDate = (date) => {
      return new Date(date).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
    };

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
    };

    const submitAction = async () => {
      if (props.isSubmitting) return;
      emit('post-submit', props.post.id, props.post.state);
    };

    const confirmDelete = async () => {
      if (confirm("Are you sure you want to delete this post?")) {
        try {
          const response = await fetch(`/profile/models/${props.post.id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
          });

          const result = await response.json();

          if (response.ok && result.success) {
            emit('post-deleted', props.post);
          } else {
            throw new Error(result.message || 'Failed to delete post');
          }
        } catch (error) {
          console.error('Error deleting post:', error);
          alert(error.message);
        }
      }
    };

    return {
      formatDate,
      formatCurrency,
      submitAction,
      confirmDelete,
      mayAskForSubmit
    };
  }
});
</script>
