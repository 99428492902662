import { createApp } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';

import InboxIndex from '../pages/InboxIndex.vue';

class InboxIndexComponent {
  constructor({ container, profile_id }) {
    this.app = null;
    this.container = container;
    this.profile_id = profile_id;
  }

  init() {
    this.app = createApp(InboxIndex, {
      profile_id: this.profile_id
    });
    this.app.use(I18NextVue, { i18next });
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });

    // Mount the Vue app to the specified container
    this.app.mount(this.container);
  }
}

export default InboxIndexComponent;
