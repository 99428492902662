<template>
  <div :class="`inline-flex pb-2 smd:pb-0 smd:px-4 items-center ${ currentBaseCategory ? 'cursor-pointer' : ''}`"
       @click.prevent="returnAllCategoryList()">
    <img src="../../images/icons/all-categories.svg" class="w-3.5 mr-2"/>
    <span class="leading-8 smd:leading-10 text-14px smd:text-16px">
      <template v-if="currentBaseCategory" >
        {{ currentBaseCategory.i18n_name }}
      </template>
      <template v-else>
        {{ $t('posts.index.categories') }}
      </template>
    </span>
  </div>

  <div class='category-tag inline-flex flex-1 flex-wrap gap-3'>
    <template v-for="cate in currentCategoriesList">
      <a :href="'/posts?c=' + cate.id"
        :class="`post-category inline-flex px-4 py-1 smd:py-2 h-8 smd:h-10 rounded-full text-14px smd:text-16px cursor-pointer ${ isSelectedCate(cate) ? 'bg-primary hover:bg-orange-10' : 'bg-grey-9 hover:bg-grey-2' }`"
        @click.prevent="redirectCateFilter(cate)">
        {{ cate.i18n_name }}

        <img v-if="cate.name == 'NSFW'" src="../../images/icons/nsfw2.svg" class="w-6 h-6 ml-2"/>
      </a>
    </template>
  </div>

  <div v-if="currentBaseCategory" id="category-filter-cleaner"
      class="absolute -right-1 smd:relative smd:right-0 cursor-pointer p-2 smd:py-3"
      @click.prevent="cleanCategoryFiter()">
    <img src="../../images/icons/close.svg" class="w-4">
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    category_ids: {
      type: Array,
      required: false
    },
  },
  data() {
    return {
      init: true,
      categoriesData: [], // all cate data
      currentBaseCategory: null, // 目前選定的 cate
      currentCategoriesList: [],
      currentSiblingIDs: []
    };
  },
  beforeMount() {
    this.getCategoriesData()
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.resizeFilterBar()
    })
  },
  updated() {
    if (this.init) {
      this.getCurrentCategory()
      this.init = false
    }

    this.resizeFilterBar()
  },
  methods: {
    async getCategoriesData() {
      try {
        const api_path = '/api/v1/categories'
        const response = await fetch(api_path);

        if (response.ok) {
          const data = await response.json();
          this.categoriesData = data['data']
          this.currentCategoriesList = data['data']
        }
      } catch (error) {
        this.categoriesData = []
        this.currentCategoriesList = []
      }
    },
    getCurrentCategory () {
      this.categoriesData.forEach((cate_lv1) => {
        const childrenIDs = cate_lv1.children.map((cate) => cate.id)

        if (this.category_ids.includes(cate_lv1.id)) {
          this.currentBaseCategory = cate_lv1
          this.currentCategoriesList = cate_lv1.children
        } else if (this.category_ids.filter(item => childrenIDs.includes(item)).length > 0) {
          this.currentBaseCategory = cate_lv1
          this.currentCategoriesList = cate_lv1.children
          this.currentSiblingIDs = childrenIDs
        }
      })
    },
    resizeFilterBar() {
      const fixedBar = document.querySelector('#fixedbar')
      const content = fixedBar.nextElementSibling

      if (window.innerWidth >= 705) {
        content.style.marginTop = fixedBar.scrollHeight + 'px'
      } else {
        content.style.marginTop = '0px'
      }
    },
    returnAllCategoryList() {
      this.currentBaseCategory = null
      this.currentCategoriesList = this.categoriesData
    },
    cleanCategoryFiter() {
      const currParams = new URLSearchParams(window.location.search);
      const paramsObject = Object.fromEntries(currParams);

      delete paramsObject['c'];

      let newParams = new URLSearchParams(paramsObject);
      window.location.href = "/posts?" + newParams;
    },
    isSelectedCate (cate) {
      return this.currentSiblingIDs.includes(cate.id) && this.category_ids.includes(cate.id)
    },
    redirectCateFilter(cate) {
      const currParams = new URLSearchParams(window.location.search);
      const paramsObject = Object.fromEntries(currParams);

      let cateIDs = paramsObject['c'] ? paramsObject['c'].split(',') : []

      if (this.category_ids.includes(cate.id)) {
        // category in params, remove cate
        cateIDs = cateIDs.filter((value) => value !== cate.id.toString());
      } else if (this.currentSiblingIDs.includes(cate.id)) {
        // category with SAME parent_id and NOT in params, add cate
        cateIDs.push(cate.id)
      } else {
        // category with DIFF parent_id, replace cate
        cateIDs = [cate.id]
      }

      paramsObject['c'] = cateIDs.join(',')

      delete paramsObject['page'];

      let newParams = new URLSearchParams(paramsObject);
      window.location.href = "/posts?" + newParams;
    }
  }
});
</script>
