<template>
  <div class="w-full flex flex-col"> 
    <div id="designer-post-collections">
      <template v-if="isPostsPath">
        <div v-if="hasCollections" class="w-full mt-2 -mb-4"> {{ $t('home.index.featured_model') }} </div>
        <template v-if="hasCollections">
          <designer-collection-item 
            v-for="collection in collections.slice(0, 3)" 
            :key="collection.id" 
            :collection="collection"
            :is_owner="isCollectionOwner"
            @collection-deleted="handleCollectionDeleted">
          </designer-collection-item>

          <a 
            v-if="hasMoreCollections"
            href="./collections"  
            class="w-full inline-block underline text-center text-12px text-grey-3 mx-auto">
            {{ $t('post_index_collection.see_more_collections') }}
          </a>
          <hr class="w-full border-t mb-4 border-grey-1">
        </template>
        <div v-else-if="isCollectionOwner" class="w-full flex flex-col items-center justify-center">
          <div class="w-12 h-12 icon archive mt-5 lg:mt-10"></div>
          <div class="text-16px my-6">{{ $t('post_index_collection.no_collection_list') }}</div>
          <a  href="collections/new"  
              class="flex items-center justify-center mb-10 text-14px btn btn-secondary rounded-[20px]">
            <div class="w-3 h-3 brightness-150 transform -rotate-45 icon close mr-2"></div>
            {{ $t('post_index_collection.new_collection') }}
          </a>
          <hr class="w-full border-t mt-4 mb-5 border-grey-1">
        </div>
      </template>
    </div>
    <div class="mt-2 my-2"> {{ $t('private_profiles.analytic.all_models') }} </div>
    <div id="post-item-collection" class="flex-1">
      <div v-if="items && items.length > 0" v-for="post in items" :key="post.id">
        <post-item :post="post" :is_authenticated="is_authenticated"></post-item>
      </div>
      <div v-else-if="isPostsLoading" class="text-center post-item-loading">
        {{ $t('post_index_collection.loading') }}
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent } from "vue";

export default defineComponent({
  props: {
    scope: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      currPage: 0,
      autoload: true,
      is_authenticated: false,
      isPostsLoading: false,
      username: '',
      itemsCols: 4,
      itemsRows: 2,
      itemsCount: 8,
      items_not_found: false,
      items_data: [],
      items: [],

      collections: [],
      isCollectionsLoading: false,
      totalCollections: 0,
      isCollectionOwner: false,
    };
  },
  computed: {
    isPostsPath() {
      return window.location.pathname.endsWith('/posts')
    },
    hasCollections() {
      return this.collections && this.collections.length > 0
    },
    hasMoreCollections() {
      return this.collections.length > 3
    },
  },
  mounted() {
    this.setupItemColsCount()
    this.username = this.getUsernameFromURL();

    if (this.isPostsPath) {
      this.fetchCollections();
    }

    this.fetchItemData(this.currPage, this.itemsCount*2)
    this.currPage += 1

    if (window.isFirefox()) {
        window.addEventListener('scroll', this.handleScrollEvent, true);
      } else {
        window.addEventListener('scroll', this.handleScrollEvent);
      }
  },
  methods: {
    getUsernameFromURL() {
      const url = window.location.href;
      const match = this.scope === 'saved_posts' ? url.match(/\/p\/([^\/]+)\/saved_posts/) : url.match(/\/p\/([^\/]+)\/posts/);
      return match ? match[1] : '';
    },
    
    async fetchItemData(currPage, perPage) {
      const url = "/api/v1/profiles/" + this.username + "/get_" + this.scope
      this.isPostsLoading = true;
      this.items_not_found = false;
      // 資料要往後拉，所以 +1
      let curr_params = {
        page: (currPage || this.currPage) + 1,
        per_page: perPage || this.itemsCount
      };
      
      try {
        const response = await fetch(
          url + "?" + new URLSearchParams(curr_params)
        );

        if (response.ok) {
          this.isPostsLoading = false;

          const data = await response.json();
          const itemsData = data['data'];
          this.is_authenticated = data['is_authenticated']
          // 如果 params page > resp total_page
          // 等於拉到底了，就不用 autoload 了
          if (curr_params['page'] > data['pagination']['total_pages']) {
            this.autoload = false;
          } else if (itemsData.length > 0) {
            // 如果有資料就要塞到 items 裏面，並更新頁碼
            this.items.push(...itemsData);
            this.currPage++;
          }
        }
      } catch (error) {
        console.log(error);
        this.items = [];
        this.autoload = false;
      }
    },

    async fetchCollections() {
      if (this.isCollectionsLoading) return;

      const url = `/api/v1/profiles/${this.username}/get_designer_collections`;
      
      let curr_params = {
        mode: 'preview',
        per_page: 3
      };
      
      this.isCollectionsLoading = true;

      try {
        const response = await fetch(
          url + "?" + new URLSearchParams(curr_params)
        );

        if (response.ok) {
          const data = await response.json();

          this.collections = data.data;
          this.totalCollections = data.pagination.total_count;
          this.isCollectionOwner = data.is_owner;
        }
      } catch (error) {
        console.log(error);
        this.collections = [];
      } finally {
        this.isCollectionsLoading = false;
      }
    },

    setupItemColsCount() {
      if (window.innerWidth >= 1024) {
        this.itemsCols = 4;
      } else if (window.innerWidth >= 780) {
        this.itemsCols = 3;
      } else if (window.innerWidth >= 705) {
        this.itemsCols = 2;
      } else {
        this.itemsCols = 1;
      }

      if (this.itemsCols != 1) {
        this.itemsCount = this.itemsCols * this.itemsRows;
      } else {
        this.itemsCount = 10;
      }
    },
    handleScrollEvent() {
      if (!this.autoload) {
        return;
      }
      const scrollableHeight = document.body.scrollHeight - window.innerHeight - 150;
      const scrolledDistance = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      );

      if (scrolledDistance >= scrollableHeight) {
        if (!this.isPostsLoading && this.autoload) {
          this.fetchItemData();
        }
      }
    },
    handleCollectionDeleted(collectionId) {
      this.collections = this.collections.filter(collection => collection.id !== collectionId);
      document.querySelector('.collections-count').textContent = `(${this.totalCollections - 1})`;
    }
  }
})
</script>
