import { createApp } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';

import ProfilePhrozenVerified from '../pages/ProfilePhrozenVerified.vue';

import ModelSearchForm from '../components/ModelSearchForm.vue';
import ModelStateFilter from '../components/ModelStateFilter.vue';
import PhrozenVerifiedItem from '../components/PhrozenVerifiedItem.vue';
import NavPanel from '../components/NavPanel.vue';
import PVDisclaimer from '../components/PVDisclaimer.vue';
import Modal from '../components/Modal.vue';

class ProfilePhrozenVerifiedComponent { 
  constructor({ container }) {
    this.app = null;
    this.container = container;
  }
  init() {
    this.app = createApp(ProfilePhrozenVerified);
    this.app.use(I18NextVue, { i18next });
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });

    this.app.component('model-search-form', ModelSearchForm);
    this.app.component('model-state-filter', ModelStateFilter);
    this.app.component('phrozen-verified-item', PhrozenVerifiedItem);
    this.app.component('nav-panel', NavPanel);
    this.app.component('phrozen-verified-disclaimer', PVDisclaimer);
    this.app.component('modal', Modal);

    // Mount the Vue app to the specified container
    this.app.mount(this.container);
  }
}

export default ProfilePhrozenVerifiedComponent;