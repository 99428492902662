<template>
  <div id='related-posts'>
    <!-- Skeleton screen -->
    <template v-if="!state.relatedPosts.length">
      <div class="post-item" v-for="i in 6" :key="'skeleton-'+i">
        <div class="block w-full relative">
          <div class="aspect-square bg-[#181C1F] rounded-lg relative">
            <div class="absolute bottom-2 left-2 bg-gray-800 h-4 w-16 rounded"></div>
          </div>
        </div>
      </div>
    </template>
    <template v-else v-for="post in state.relatedPosts" :key="post.id">
      <post-item :post="post" :hidden_creator="true"></post-item>
    </template>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { state } from "../stores/postState";

export default defineComponent({
  data() {
    return {
      state
    };
  },
  mounted() {
    this.state.post_id = window.location.href.match(/\/posts\/(\d+)/)[1]
    this.state.relatedPosts = []

    this.getRelatedPosts()
  },
  methods: {
    async getRelatedPosts(){
      try {
        const api_path = '/api/v1/posts/' + this.state.post_id + '/related_posts'
        const response = await fetch(api_path);

        if (response.ok) {
          const data = await response.json();
          const postsData = data['data']

          if (postsData.length > 0) {
            this.state.relatedPosts = postsData;
          }
        }
      } catch (error) {
        this.state.relatedPosts = []
      }
    }
  }
});
</script>
