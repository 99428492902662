import { createApp } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';

import ProfileSortModels from '../pages/ProfileSortModels.vue';

import SortedModelItem from '../components/SortedModelItem.vue';
import NavPanel from '../components/NavPanel.vue';

class ProfileSortModelsComponent {
  constructor({ container }) {
    this.app = null;
    this.container = container;
  }
  init() {
    this.app = createApp(ProfileSortModels);
    this.app.use(I18NextVue, { i18next });
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });

    this.app.component('sorted-model-item', SortedModelItem);
    this.app.component('nav-panel', NavPanel);
    // Mount the Vue app to the specified container
    this.app.mount(this.container);
  }
}

export default ProfileSortModelsComponent;
