import AutocompleteBar from '../vue/controllers/AutocompleteBar.js';
import CookieModalComponent from '../vue/controllers/cookie_modal.js';

import HomePostComponent from '../vue/controllers/home_post_collection.js';
import CategoryFilterComponent from '../vue/controllers/category_filter_component.js';
import PostIndexComponent from '../vue/controllers/post_index_collection.js';
import PostIndexProfileCollectionComponent from '../vue/controllers/post_index_profile_collection.js';

import PostCommentsComponent from '../vue/controllers/post_comments_component.js';
import PostImageSlideComponent from '../vue/controllers/post_image_slide_component.js';
import PostRelatedPostsComponent from '../vue/controllers/post_related_posts_component.js';

import CampaignIndexComponent from '../vue/controllers/campaign_index.js';

import CartIndexComponent from '../vue/controllers/cart_index.js';
import InboxIndexComponent from '../vue/controllers/inbox_index.js';

import PublicProfileFollowComponent from '../vue/controllers/public_profile_follow_collection.js';
import PublicProfileReviewComponent from '../vue/controllers/public_profile_review_collection.js';
import PublicProfileModelComponent from '../vue/controllers/public_profile_model_collection.js';
import PublicProfileDesignerCollectionComponent from '../vue/controllers/public_profile_designer_collection.js';

import ProfileModelsComponent from '../vue/controllers/profile_models.js';
import ProfilePhrozenVerifiedComponent from '../vue/controllers/profile_phrozen_verified.js';
import ProfileSortModelsComponent from '../vue/controllers/profile_sort_models.js';
import ProfileLibrariesComponent from '../vue/controllers/profile_libraries.js';

window.AutocompleteBar = AutocompleteBar
window.CookieModalComponent = CookieModalComponent

window.HomePostComponent = HomePostComponent
window.CategoryFilterComponent = CategoryFilterComponent
window.PostIndexComponent = PostIndexComponent
window.PostIndexProfileCollectionComponent = PostIndexProfileCollectionComponent

window.PostCommentsComponent = PostCommentsComponent
window.PostImageSlideComponent = PostImageSlideComponent
window.PostRelatedPostsComponent = PostRelatedPostsComponent

window.CampaignIndexComponent = CampaignIndexComponent

window.CartIndexComponent = CartIndexComponent
window.InboxIndexComponent = InboxIndexComponent

window.PublicProfileFollowComponent = PublicProfileFollowComponent
window.PublicProfileReviewComponent = PublicProfileReviewComponent
window.PublicProfileModelComponent = PublicProfileModelComponent
window.PublicProfileDesignerCollectionComponent = PublicProfileDesignerCollectionComponent

window.ProfileModelsComponent = ProfileModelsComponent
window.ProfilePhrozenVerifiedComponent = ProfilePhrozenVerifiedComponent
window.ProfileSortModelsComponent = ProfileSortModelsComponent
window.ProfileLibrariesComponent = ProfileLibrariesComponent
