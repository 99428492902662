<template>
  <div id="post-item-collection" class="flex-1">
    <div
      v-if="campaigns && campaigns.length > 0"
      v-for="post in campaigns"
      :key="post.id"
    >
      <post-item :post="post"></post-item>
    </div>
    <div v-else-if="loading" class="text-center post-item-loading skeleton-container">
      {{ $t('home_post_collection.loading') }}
      <div class="post-item-skeleton">
        <div class="skeleton-image"></div>
        <div class="skeleton-info">
          <div class="skeleton-title"></div>
          <div class="skeleton-author"></div>
        </div>
      </div>
    </div>
    <div v-if="items && items.length > 0" v-for="post in items" :key="post.id">
      <post-item :post="post"></post-item>
    </div>
    <div
      v-else-if="items_not_found && postFeed"
      class="text-center post-item-not-found"
    >
      <div class="icon"></div>
      <div class="title">{{ $t('home_post_collection.no_post_warning') }}</div>
    </div>
    <div v-else-if="items_not_found" class="text-center post-item-not-found">
      <div class="icon"></div>
      <div class="title">
        {{ $t('home_post_collection.no_matching_post') }}
      </div>
    </div>
    <div v-else-if="loading" class="text-center post-item-loading skeleton-container">
      {{ $t('home_post_collection.loading') }}
      <div class="post-item-skeleton">
        <div class="skeleton-image"></div>
        <div class="skeleton-info">
          <div class="skeleton-title"></div>
          <div class="skeleton-author"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setWithExpiry, getWithExpiry } from "../utils/localCache";
const homeItemSearchPath = "/api/v1/home/posts";
const postItemSearchPath = "/api/v1/posts";

export default {
  props: ['postFeed', 'postCollection'],
  data() {
    return {
      itemsCols: 6,
      itemsRows: 2,
      itemsCount: 12,
      
      perPage: 24,
      currPage: 0,
      autoload: this.postCollection == '', // 沒有指定 post_collection 的話就會吃 autoload，目前只有 trending post

      cacheKey: 'post_cols_cache_key' + this.postCollection + this.postCollection,

      // post list
      loading: false,
      items_not_found: false,
      campaigns: [],
      campaigns_data: [],
      items: [],
      items_data: [],
    };
  },
  mounted() {
    this.setupItemColsCount();

    if (this.autoload) {
      const cacheData = getWithExpiry(this.cacheKey);
      if (cacheData) {
        this.currPage = cacheData.page;
        this.items = cacheData.items;
      } else {
        this.fetchPostItemData(this.currPage, this.perPage);
      } 

      if (window.isFirefox()) {
        window.addEventListener('scroll', this.handleScrollEvent, true);
      } else {
        window.addEventListener('scroll', this.handleScrollEvent);
      }
    } else {
      const cacheData = getWithExpiry(this.cacheKey);
      if (cacheData) {
        this.campaigns_data = cacheData.campaigns_data;
        this.items_data = cacheData.items_data;

        const campaigns_post = this.campaigns_data.slice(0, this.itemsCount);
        this.campaigns.push(...campaigns_post);

        const feed_post = this.items_data.slice(0, this.itemsCount - campaigns_post.length);
        this.items.push(...feed_post);
      } else {
        this.fetchHomeItemData();
      }

      window.addEventListener("resize", () => {
        this.setupItemColsCount();
        this.campaigns = [];
        this.items = [];

        const campaigns_post = this.campaigns_data.slice(0, this.itemsCount);
        this.campaigns.push(...campaigns_post);

        const feed_post = this.items_data.slice(0, this.itemsCount - campaigns_post.length);
        this.items.push(...feed_post);
      });
    }
  },
  methods: {
    async fetchHomeItemData() {
      try {
        this.loading = true;
        this.items_not_found = false;

        const response = await fetch(
          homeItemSearchPath +
            "?" +
            new URLSearchParams({
              kw: this.postFeed,
              post_collection_kw: this.postCollection,
              per_page: this.perPage
            })
        );
        if (response.ok) {
          const data = await response.json();
          const campaignsData = data["campaign"];
          const itemsData = data["data"];

          this.campaigns = [];
          this.campaigns_data = [];
          this.items = [];
          this.items_data = [];

          if (campaignsData.length == 0 && itemsData.length == 0) {
            this.items_not_found = true;
          } else {
            this.campaigns_data.push(...campaignsData);
            this.items_data.push(...itemsData);

            const campaigns_post = this.campaigns_data.slice(0, this.itemsCount);
            this.campaigns.push(...campaigns_post);

            const feed_post = this.items_data.slice(0, this.itemsCount - campaigns_post.length);
            this.items.push(...feed_post);

            const cacheData = {
              campaigns_data: this.campaigns_data,
              items_data: this.items_data,
            };

            setWithExpiry(this.cacheKey, cacheData, 10000);
          }
          this.loading = false;
        }
      } catch (error) {
        this.campaigns = [];
        this.items = [];
      }
    },
    async fetchPostItemData(currPage, perPage) {
      try {
        if (this.loading) return;

        this.loading = true;

        const curr_params = {
          'kw': this.postFeed,
          'per_page': perPage || this.perPage,
          'page': (currPage || this.currPage) + 1
        };

        const response = await fetch(
          postItemSearchPath + "?" + new URLSearchParams(curr_params)
        );
        if (response.ok) {
          const data = await response.json();
          const itemsData = data["data"];

          // 如果 params page > resp total_page
          // 等於拉到底了，就不用 autoload 了
          if (curr_params["page"] > data["pagination"].total_pages) {
            this.autoload = false;
          } else if (itemsData.length > 0) {
            // 如果有資料就要塞到 state 裏面，並更新頁碼
            this.items.push(...itemsData);
            this.currPage++;

            const cacheData = {
              items: this.items,
              page: curr_params["page"],
            };

            setWithExpiry(this.cacheKey, cacheData, 10000);
          }
          this.loading = false;

        }
      } catch (error) {
        this.items = [];
        this.autoload = false;
      }
    },
    setupItemColsCount() {
      if (window.innerWidth >= 1920) {
        this.itemsCols = 6;
      } else if (window.innerWidth >= 1536) {
        this.itemsCols = 5;
      } else if (window.innerWidth >= 1024) {
        this.itemsCols = 4;
      } else if (window.innerWidth >= 780) {
        this.itemsCols = 3;
      } else if (window.innerWidth >= 705) {
        this.itemsCols = 2;
      } else {
        this.itemsCols = 1;
      }

      if (this.itemsCols == 1) {
        this.itemsCount = 6;
      } else {
        this.itemsCount = this.itemsCols * this.itemsRows;
      }
    },
    handleScrollEvent() {
      if (!this.autoload) return
      const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight - 1000;
      const scrolledDistance = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      );
      if (scrolledDistance >= scrollableHeight) {
        if (!this.loading) {
          this.fetchPostItemData();
        }
      }
    }
  },
};
</script>
