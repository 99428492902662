import { createApp } from 'vue';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';

import CategoryFilter from '../pages/CategoryFilter.vue';

class CategoryFilterComponent {
  constructor({ container, category_ids }) {
    this.app = null;
    this.container = container;
    this.category_ids = category_ids;
  }
  init() {
    this.app = createApp(CategoryFilter, {
      category_ids: this.category_ids
    });
    this.app.use(I18NextVue, { i18next });

    this.app.mount(this.container);
  }
}

export default CategoryFilterComponent;
