<template>
  <div>{{ formattedPrice }}</div>
</template>

<script>
  import { defineComponent } from "vue";
  import { currencyFormat } from '../../components/exchange_rates.js';

  export default defineComponent({
    props: ['price'],
    
    data() {
      return {
        currency: 'USD',
        rate: null,
      };
    },
    mounted() {
      const exchangeRatesData = localStorage.getItem('exchangeRatesData');
      this.currency = window.global_values['CURRENCY'];
      // 交易對格式 USDJPY,USDTWD
      // 沒有匯率的話就用 USD
      if(exchangeRatesData) {
        this.rate = JSON.parse(exchangeRatesData).rates[`USD${this.currency}`]['Exrate']
      } else {
        this.currency = 'USD'
        this.rate = 1
      }
    },
    computed: { 
      formattedPrice() {
        let price = this.price

        if (price >= 0) {
          return currencyFormat[this.currency]['unit'] + ` ${(price * this.rate).toFixed(currencyFormat[this.currency]['decimal'])}`
        } else {
          price = Math.abs(price)
          return '-' + currencyFormat[this.currency]['unit'] + ` ${(price * this.rate).toFixed(currencyFormat[this.currency]['decimal'])}`
        }

      } 
    }
  })
</script>
