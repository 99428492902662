import { createApp } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';

import CartIndex from '../pages/CartIndex.vue';

import Currency from '../components/Currency.vue';
import isoDateToUTC from '../components/isoDateToUTC.vue';

class CartIndexComponent {
  constructor({ container }) {
    this.app = null;
    this.container = container;
  }

  // Initialize the component
  init() {
    this.app = createApp(CartIndex);
    this.app.use(I18NextVue, { i18next });
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });

    this.app.component('Currency', Currency);
    this.app.component('IsoToUTC', isoDateToUTC);

    // Mount the Vue app to the specified container
    this.app.mount(this.container);
  }
}

export default CartIndexComponent;
