import { createApp } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';

import ProfileCollection from '../pages/ProfileCollection.vue';

class PostIndexProfileCollectionComponent {
  constructor({ container }) {
    this.app = null;
    this.container = container;
  }
  init() {
    this.app = createApp(ProfileCollection);
    this.app.use(I18NextVue, { i18next });
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });

    // Mount the Vue app to the specified container
    this.app.mount(this.container);
  }
}

export default PostIndexProfileCollectionComponent;
